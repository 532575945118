var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "v-container",
        { attrs: { fluid: "" } },
        [
          _c(
            "v-row",
            [_c("v-col", [_c("h3", [_vm._v(_vm._s(_vm.title))]), _c("hr")])],
            1
          ),
          _c(
            "v-row",
            [
              _c(
                "v-col",
                [
                  _c("v-data-table", {
                    attrs: {
                      headers: _vm.headers,
                      items: _vm.list,
                      "hide-default-footer": "",
                      "no-data-text": "Não há tipos de ocorrências cadastrados",
                      loading: false
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "item",
                        fn: function(props) {
                          return [
                            _c("tr", [
                              _c("td", { staticClass: "text-xs-left" }, [
                                _vm._v(_vm._s(props.item.id))
                              ]),
                              _c("td", { staticClass: "text-xs-left" }, [
                                _vm._v(_vm._s(props.item.origin))
                              ]),
                              _c("td", { staticClass: "text-xs-left" }, [
                                _vm._v(_vm._s(props.item.name))
                              ]),
                              _c("td", { staticClass: "text-xs-left" }, [
                                props.item.removed
                                  ? _c("span", [_vm._v("Desativado")])
                                  : _c("span", [_vm._v("Ativado")])
                              ]),
                              _c(
                                "td",
                                { staticClass: "text-xs-left" },
                                [
                                  _c(
                                    "v-btn",
                                    {
                                      attrs: {
                                        disabled: props.item.removed == true,
                                        outlined: "",
                                        color: "green lighten-2",
                                        small: ""
                                      },
                                      on: {
                                        click: function($event) {
                                          return _vm.showDialogUpdateOccurrenceType(
                                            props.item
                                          )
                                        }
                                      }
                                    },
                                    [
                                      _c("v-icon", { attrs: { small: "" } }, [
                                        _vm._v("create")
                                      ])
                                    ],
                                    1
                                  )
                                ],
                                1
                              ),
                              _c("td", { staticClass: "text-xs-left" }, [
                                props.item.removed == false
                                  ? _c(
                                      "div",
                                      [
                                        _c(
                                          "v-btn",
                                          {
                                            attrs: {
                                              outlined: "",
                                              color: "red lighten-2",
                                              small: ""
                                            },
                                            on: {
                                              click: function($event) {
                                                return _vm.delAsyncOccurrenceType(
                                                  props.item.id
                                                )
                                              }
                                            }
                                          },
                                          [
                                            _c("v-icon", [
                                              _vm._v("delete_outline")
                                            ])
                                          ],
                                          1
                                        )
                                      ],
                                      1
                                    )
                                  : _c(
                                      "div",
                                      [
                                        _c(
                                          "v-btn",
                                          {
                                            attrs: {
                                              outlined: "",
                                              color: "grey",
                                              small: ""
                                            },
                                            on: {
                                              click: function($event) {
                                                return _vm.delAsyncOccurrenceType(
                                                  props.item.id
                                                )
                                              }
                                            }
                                          },
                                          [
                                            _c("v-icon", [
                                              _vm._v("restore_from_trash")
                                            ])
                                          ],
                                          1
                                        )
                                      ],
                                      1
                                    )
                              ])
                            ])
                          ]
                        }
                      }
                    ])
                  })
                ],
                1
              )
            ],
            1
          ),
          _c(
            "v-row",
            [
              _c(
                "v-col",
                { attrs: { "v-show": _vm.progress } },
                [
                  _c("v-progress-linear", {
                    attrs: {
                      size: 50,
                      color: "red lighten-2",
                      active: _vm.progress,
                      indeterminate: ""
                    }
                  })
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "v-dialog",
        {
          attrs: { "max-width": "400" },
          on: {
            keydown: function($event) {
              if (
                !$event.type.indexOf("key") &&
                _vm._k($event.keyCode, "esc", 27, $event.key, ["Esc", "Escape"])
              ) {
                return null
              }
              _vm.dialog = false
            }
          },
          model: {
            value: _vm.dialog,
            callback: function($$v) {
              _vm.dialog = $$v
            },
            expression: "dialog"
          }
        },
        [
          _c("OccurenceTypeDialogUpdate", {
            attrs: { item: _vm.item },
            on: { "close-dialog": _vm.closeDialog }
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }