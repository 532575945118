var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    [
      _c("v-flex", [_c("h3", [_vm._v("Tipos de Ocorrências")])]),
      _c("br"),
      _c(
        "v-flex",
        [
          _c(
            "v-card",
            { attrs: { outlined: "" } },
            [
              _c(
                "v-card-title",
                [
                  _c(
                    "v-container",
                    [
                      _c(
                        "v-row",
                        {
                          attrs: {
                            justify: "center",
                            align: "center",
                            wrap: ""
                          }
                        },
                        [
                          _c(
                            "v-col",
                            { attrs: { cols: "12", md: "3" } },
                            [
                              _c("v-select", {
                                attrs: {
                                  outlined: "",
                                  dense: "",
                                  items: _vm.types,
                                  label: "Grupo",
                                  disabled: _vm.disabled
                                },
                                model: {
                                  value: _vm.group,
                                  callback: function($$v) {
                                    _vm.group = $$v
                                  },
                                  expression: "group"
                                }
                              })
                            ],
                            1
                          ),
                          _c(
                            "v-col",
                            { attrs: { cols: "12", md: "7" } },
                            [
                              _c("v-text-field", {
                                staticClass: "mb-4",
                                attrs: {
                                  placeholder: "Tipo de Ocorrência",
                                  disabled: _vm.disabled
                                },
                                on: {
                                  keypress: function($event) {
                                    if (
                                      !$event.type.indexOf("key") &&
                                      _vm._k(
                                        $event.keyCode,
                                        "enter",
                                        13,
                                        $event.key,
                                        "Enter"
                                      )
                                    ) {
                                      return null
                                    }
                                    return _vm.postAsyncOccurrenceType($event)
                                  }
                                },
                                model: {
                                  value: _vm.name,
                                  callback: function($$v) {
                                    _vm.name = $$v
                                  },
                                  expression: "name"
                                }
                              })
                            ],
                            1
                          ),
                          _c(
                            "v-col",
                            { attrs: { cols: "12", md: "2" } },
                            [
                              _c(
                                "v-btn",
                                {
                                  staticClass: "mb-4",
                                  attrs: {
                                    outlined: "",
                                    color: "green lighten-2",
                                    disabled: _vm.disabled
                                  },
                                  on: { click: _vm.postAsyncOccurrenceType }
                                },
                                [
                                  _c(
                                    "v-icon",
                                    { attrs: { left: "", dark: "" } },
                                    [_vm._v("add")]
                                  ),
                                  _vm._v("Adicionar ")
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _c(
                            "v-col",
                            { attrs: { cols: "12", "v-show": _vm.disabled } },
                            [
                              _c("v-progress-linear", {
                                attrs: {
                                  size: 50,
                                  color: "green lighten-2",
                                  active: _vm.disabled,
                                  indeterminate: ""
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "v-flex",
        { attrs: { "mt-5": "" } },
        [
          _c("occurrence-type-table", {
            attrs: { title: "Administrador", items: _vm.admin }
          })
        ],
        1
      ),
      _c(
        "v-flex",
        { attrs: { "mt-1": "" } },
        [
          _c("occurrence-type-table", {
            attrs: { title: "Secretaria", items: _vm.sec }
          })
        ],
        1
      ),
      _c(
        "v-flex",
        { attrs: { "mt-1": "" } },
        [
          _c("occurrence-type-table", {
            attrs: { title: "Professor", items: _vm.prof }
          })
        ],
        1
      ),
      _c(
        "v-flex",
        { attrs: { "mt-1": "" } },
        [
          _c("occurrence-type-table", {
            attrs: { title: "Aluno", items: _vm.student }
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }