<template>
  <div>
    <v-container fluid>
      <v-row>
        <v-col>
          <h3>{{ title }}</h3>
          <hr />
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-data-table
            v-bind:headers="headers"
            v-bind:items="list"
            hide-default-footer
            no-data-text="Não há tipos de ocorrências cadastrados"
            :loading="false"
          >
            <template v-slot:item="props">
              <tr>
                <td class="text-xs-left">{{ props.item.id }}</td>
                <td class="text-xs-left">{{ props.item.origin }}</td>
                <td class="text-xs-left">{{ props.item.name }}</td>
                <td class="text-xs-left">
                  <span v-if="props.item.removed">Desativado</span>
                  <span v-else>Ativado</span>
                </td>
                <td class="text-xs-left">
                  <v-btn
                    :disabled="props.item.removed == true"
                    outlined
                    color="green lighten-2"
                    small
                    @click="showDialogUpdateOccurrenceType(props.item)"
                  >
                    <v-icon small>create</v-icon>
                  </v-btn>
                </td>
                <td class="text-xs-left">
                  <div v-if="props.item.removed == false">
                    <v-btn
                      outlined
                      color="red lighten-2"
                      small
                      @click="delAsyncOccurrenceType(props.item.id)"
                    >
                      <v-icon>delete_outline</v-icon>
                    </v-btn>
                  </div>
                  <div v-else>
                    <v-btn
                      outlined
                      color="grey"
                      small
                      @click="delAsyncOccurrenceType(props.item.id)"
                    >
                      <v-icon>restore_from_trash</v-icon>
                    </v-btn>
                  </div>
                </td>
              </tr>
            </template>
          </v-data-table>
        </v-col>
      </v-row>
      <v-row>
         <v-col :v-show="progress">
          <v-progress-linear :size="50" color="red lighten-2" :active="progress" indeterminate></v-progress-linear>
        </v-col>
      </v-row>
    </v-container>

    <v-dialog v-model="dialog" max-width="400" @keydown.esc="dialog=false">
      <OccurenceTypeDialogUpdate :item="item" v-on:close-dialog="closeDialog"></OccurenceTypeDialogUpdate>
    </v-dialog>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import OccurenceTypeDialogUpdate from "./OccurrenceTypeDialogUpdate";

export default {
  name: "occurrence_type_table",
  props: ["title", "items"],
  components: { OccurenceTypeDialogUpdate },
  data() {
    return {
      list: [],
      headers: [
        { sortable: true, text: "Código", value: "id", align: "left" },
        { sortable: true, text: "Grupo", value: "origin", align: "left" },
        { sortable: true, text: "Descrição", value: "name", align: "left" },
        { sortable: true, text: "Status", value: "removed", align: "left" },
        { sortable: false, text: "Editar", value: "edit", align: "left" },
        { sortable: false, text: "Modificar", value: "remove", align: "left" },
      ],
      item: {},
      dialog: false,
      progress: false,
    };
  },

  methods: {
    ...mapActions("occurrenceType", ["ActionDelOccurrenceType"]),

    async delAsyncOccurrenceType(id) {
      this.progress = true;
      try {
        await this.ActionDelOccurrenceType(id);
      } catch (err) {
        console.error(err);
      } finally {
        this.progress = false;
      }
    },

    showDialogUpdateOccurrenceType(item) {
      this.item = item;
      this.dialog = !this.dialog;
    },

    async putAsyncOccurenceType(item) {
      console.log(item);
    },

    closeDialog: function () {
      this.dialog = false;
    },
  },

  watch: {
    items(newValue) {
      this.list = [];
      newValue.map((i) => {
        this.list.push({
          id: i.id,
          origin: i.origin,
          name: i.name,
          removed: i.removed,
        });
      });
    },
  },
};
</script>