<template>
  <v-container>
    <v-flex>
      <h3>Tipos de Ocorrências</h3>
    </v-flex>
    <br />
    <v-flex>
      <v-card outlined>
        <v-card-title>
          <v-container>
            <v-row justify="center" align="center" wrap>
              <v-col cols="12" md="3">
                <v-select
                  outlined
                  dense
                  :items="types"
                  label="Grupo"
                  v-model="group"
                  :disabled="disabled"
                ></v-select>
              </v-col>
              <v-col cols="12" md="7">
                <v-text-field
                  placeholder="Tipo de Ocorrência"
                  class="mb-4"
                  v-model="name"
                  :disabled="disabled"
                  @keypress.enter="postAsyncOccurrenceType"
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="2">
                <v-btn
                  outlined
                  color="green lighten-2"
                  class="mb-4"
                  @click="postAsyncOccurrenceType"
                  :disabled="disabled"
                >
                  <v-icon left dark>add</v-icon>Adicionar
                </v-btn>
              </v-col>
              <v-col cols="12" :v-show="disabled">
                <v-progress-linear
                  :size="50"
                  color="green lighten-2"
                  :active="disabled"
                  indeterminate
                ></v-progress-linear>
              </v-col>
            </v-row>
          </v-container>
        </v-card-title>
      </v-card>
    </v-flex>
    <v-flex mt-5>
      <occurrence-type-table title="Administrador" :items="admin"></occurrence-type-table>
    </v-flex>
    <v-flex mt-1>
      <occurrence-type-table title="Secretaria" :items="sec"></occurrence-type-table>
    </v-flex>
    <v-flex mt-1>
      <occurrence-type-table title="Professor" :items="prof"></occurrence-type-table>
    </v-flex>
    <v-flex mt-1>
      <occurrence-type-table title="Aluno" :items="student"></occurrence-type-table>
    </v-flex>
  </v-container>
</template>

<script>
import { mapState, mapActions } from "vuex";
import OccurrenceTypeTable from "./OccurenceTypeTable";

export default {
  name: "occurrence_type",
  components: { OccurrenceTypeTable },
  data() {
    return {
      types: [],
      admin: [],
      sec: [],
      prof: [],
      student: [],
      group: "",
      name: "",
      disabled: false,
    };
  },
  created() {
    this.getAsyncOccurrenceType();
  },
  computed: {
    ...mapState("occurrenceType", ["occurrenceType", "occurrenceTypes"]),
  },
  methods: {
    ...mapActions("occurrenceType", [
      "ActionGetOccurrenceType",
      "ActionGetOccurrenceTypes",
      "ActionPostOccurrenceType",
    ]),

    async getAsyncOccurrenceType() {
      try {
        await this.ActionGetOccurrenceType();
        await this.ActionGetOccurrenceTypes();
      } catch (err) {
        console.error(err);
      }
    },

    async postAsyncOccurrenceType() {
      if (this.name == "" || this.group == "")
        alert("Todos os campos são obrigatórios!");
      else {
        this.disabled = true;
        try {
          await this.ActionPostOccurrenceType({
            origin: this.group,
            name: this.name,
          });
          this.name = "";
          this.group = "";
        } catch (err) {
          console.error(err);
        } finally {
          this.disabled = false;
        }
      }
    },
  },
  watch: {
    occurrenceType(newValue) {
      this.admin = []
      this.sec = []
      this.prof = []
      this.student = []

      newValue.types.map((l) =>
        l.map((sub) => {
          if (sub.origin === "Administração") this.admin.push(sub);
          if (sub.origin === "Secretaria") this.sec.push(sub);
          if (sub.origin === "Professor") this.prof.push(sub);
          if (sub.origin === "Aluno") this.student.push(sub);
        })
      );
    },
    occurrenceTypes(newValue) {
      this.types = newValue;
    },
  },
};
</script>