<template>
  <v-container fluid>
    <v-card>
      <v-card-title>
        <v-row>
          <v-col cols="12" class="text-center">
            <h4 class="title">Atualizar Tipo de Ocorrência</h4>
          </v-col>
        </v-row>
      </v-card-title>
      <v-card-text>
        <v-row>
          <v-col cols="12" class="text-center">
            <v-text-field
              v-model="name"
              outlined
              @keypress.enter="putAsyncOccurrenceType"
              :disabled="disable"
              :autofocus="true"
            ></v-text-field>
          </v-col>
          <v-col cols="12" class="text-center">
            <v-progress-circular indeterminate color="green" v-show="disable == true"></v-progress-circular>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions>
        <v-row justify="center" align="center" wrap>
          <v-col cols="12" class="text-center">
            <v-btn
              color="green"
              outlined
              @click="putAsyncOccurrenceType"
              :disabled="disable"
            >Atualizar</v-btn>
          </v-col>
        </v-row>
      </v-card-actions>
    </v-card>
  </v-container>
</template>

<script>
import { mapActions } from "vuex";

export default {
  name: "occurrence_type_dialog_update",
  props: ["item"],
  data() {
    return {
      occurrenceType: {},
      name: "",
      disable: false,
    };
  },

  created() {
    this.occurrenceType = this.item;
    if (this.occurrenceType) this.name = this.occurrenceType.name;
  },

  methods: {
    ...mapActions("occurrenceType", ["ActionPutOccurrenceType"]),

    async putAsyncOccurrenceType() {
      this.occurrenceType.name = this.name;
      try {
        this.disable = true;
        await this.ActionPutOccurrenceType(this.occurrenceType);
      } catch (err) {
        console.error(err);
      } finally {
        this.disable = false;
        this.$emit("close-dialog");
      }
    },
  },

  watch: {
    item(newValue) {
      if (newValue) {
        this.occurrenceType = newValue;
        this.name = this.occurrenceType.name;
      }
    },
  },
};
</script>